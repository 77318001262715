import * as qs from 'qs';
import { isDefined, isEmptyObject } from '@util/TypeGuards';
import { AccessLevel } from '@api/ApiTypes';
import { isBlank } from '@util/StringUtil';
import { ExperimentGalleryFilters, OwnershipFilter } from '@util/ExperimentUtil';
import {
    CursorParams,
    OrgSSOParams,
    PaginationParams,
    PlutoFileParams,
    SearchQueryParams,
    UploadSessionParams,
} from '@services/EndpointUtil';
import { AnalysisCategoryShortname, AnalysisShortname } from '@models/analysis/AnalysisType';
import { ReferenceSource } from '@models/LiteratureDatasets';
import { GeneSetSortField } from '@models/GeneSet';
import { Biomarker, BiomarkerList, BiomarkerSetType } from '@models/Biomarker';
import { CommentObjectType } from '@models/Comment';
import { ThreadObjectType } from '@models/Thread';
import { EvidenceType } from '../models/Evidence';
import { FileObjectType } from '../models/PlutoFile';

type BooleanString = 'true' | 'false';
export type QueryParams = Record<string, unknown>;
export type OwnershipParams = { ownership?: OwnershipFilter };
export type FilenameParams = { filename: string };
export type OwnershipQueryParams = { owned?: BooleanString; shared?: BooleanString };
export type DownloadParams = { content_type?: 'attachment'; download?: string };
export type ExperimentGalleryParams = {
    filters?: ExperimentGalleryFilters;
    exclude?: 'draft' | string;
};

export type PlotQueryParams = { display_id?: string; analysis_id?: string };
export type CommentQueryParams = {
    content?: string;
    comment_uuid?: string;
    object_type?: CommentObjectType;
    object_uuid?: string;
    comment_type?: 'comment' | 'note' | 'all';
};

export type AIQueryParams = {
    thread_uuid?: string;
    object_type?: ThreadObjectType;
    object_uuid?: string;
};

export type BiomarkerSetQueryParams = Pick<
    BiomarkerList,
    'name' | 'description' | 'organism_shortname' | 'target_type'
>;

export type BiomarkerSearchQueryParams = {
    name: string;
    gene?: BiomarkerList['target_type'];
    organism?: BiomarkerList['organism_shortname'];
    set_type?: BiomarkerSetType;
};

export type BiomarkerQueryParams = Biomarker;

export type EvidenceQueryParams = {
    object_type: 'biomarker';
    object_uuid: string;
    evidence_object_type?: EvidenceType;
    evidence_object_uuid?: string;
};

// export type AIQuestionParams = {
//     is_assistant: false;
//     content: string;
//     files: string;
// }
export type CanvasParams = {
    node?: string;
    edge?: string;
    viewport?: string;
};

export type LiteratureDatasetParams = {
    name?: string;
    description?: string;
    color?: number;
};

export type DatasetSearchParams = {
    sources: ReferenceSource[] | [];
    keywords: string[] | [];
};

export type ResourceFilesQueryParams = {
    object_type?: FileObjectType;
    object_uuid?: string;
};

export type AnalysisTypeQueryParams =
    | { analysis_types: AnalysisShortname | string | null }
    | {
          category: AnalysisCategoryShortname | null | string;
      };
export type AnalysisSummaryQueryParams = PaginationParams &
    SearchQueryParams & { sort_by?: GeneSetSortField; include?: string };
export type ApiQueryFilters = OwnershipParams & { pagination?: PaginationParams } & PaginationParams &
    ExperimentGalleryParams &
    Partial<ApiSearchParams> &
    SearchQueryParams &
    Partial<PlotQueryParams> &
    Partial<FilenameParams> &
    CursorParams &
    DownloadParams &
    AnalysisTypeQueryParams &
    AnalysisSummaryQueryParams &
    UploadSessionParams &
    PlutoFileParams &
    OrgSSOParams;

export type SearchRecordType = 'projects' | 'experiments';

export type ApiSearchParams = {
    query: string;
    type: SearchRecordType;
    access: AccessLevel;
};

export enum QueryParam {
    CONTINUE_URL = 'continueUrl',
    CONTINUE_SKIP_SETUP = 'continueSkipSetup',
    MESSAGE = 'message',
    MESSAGE_LEVEL = 'messageLevel',
    STEP = 'step',
    SCHEDULE_DEMO = 'scheduledemo',
    ANALYSIS_ID = 'analysisId',
    DISPLAY_ID = 'displayId',
}

export const makeOwnershipQueryParams = (params: OwnershipParams | null = {}): OwnershipQueryParams => {
    const query: QueryParams = {};

    if (!params) {
        return query;
    }
    const { ownership } = params;
    switch (ownership) {
        case OwnershipFilter.OWNED_BY_ME:
            query.owned = 'true';
            break;
        case OwnershipFilter.SHARED_WITH_ME:
            query.shared = 'true';
            break;
        default:
            break;
    }
    return query;
};

export const makePaginationParams = (pagination?: PaginationParams | null): QueryParams => {
    const query: QueryParams = {};
    if (!pagination || isEmptyObject(pagination)) {
        return query;
    }
    if (isDefined(pagination?.offset)) {
        query.offset = `${pagination?.offset ?? 0}`;
    }

    if (isDefined(pagination?.limit)) {
        query.limit = `${pagination?.limit}`;
    }

    return query;
};

export const makeSearchQueryParams = (params?: SearchQueryParams): QueryParams => {
    const query: QueryParams = {};

    if (params?.search && !isBlank(params?.search)) {
        query.search = encodeURIComponent(params.search);
    }

    return query;
};

export const makeExperimentGalleryParams = (filters?: Partial<ExperimentGalleryFilters>): QueryParams => {
    const query: QueryParams = {};
    if (!filters || isEmptyObject(filters)) {
        return query;
    }
    Object.keys({ ...filters })
        .filter((key) => isDefined(filters[key as keyof ExperimentGalleryFilters]))
        .forEach((key: keyof ExperimentGalleryFilters) => {
            let filterValue = filters[key];

            if (Array.isArray(filterValue)) {
                filterValue = filterValue.join(',');
            }

            query[key] = `${filterValue}`;
        });

    return query;
};

/**
 * Create query parameters with special processing for well-known objects.
 * Note: this will ignore null or undefined values. If you must pass a query param with no value, use an empty string or something similar
 * @param {(Partial<ApiQueryFilters> & QueryParams) | null} params
 * @return {QueryParams | null}
 */
export const parseApiQueryParams = (params?: (Partial<ApiQueryFilters> & QueryParams) | null): QueryParams | null => {
    const { ownership, pagination, filters, offset, limit, search, ...rest } = params ?? {};
    const query: QueryParams = {
        ...rest,
        ...makeOwnershipQueryParams({ ownership }),
        ...makePaginationParams(pagination ?? { offset, limit }),
        ...makeExperimentGalleryParams(filters),
        ...makeSearchQueryParams({ search }),
    };

    Object.keys(query).forEach((key) => {
        const value = query[key];
        if (!isDefined(value)) {
            delete query[key];
        }
    });

    if (!!query.organization) delete query.organization;

    if (Object.keys(query).length) {
        return query;
    }
    return null;
};

/**
 * Format an object of query params into a string that can be appended to a URL
 * @param {QueryParams} query
 * @return {string}
 */
export const makeQueryString = (query: QueryParams) => {
    return qs.stringify(query, { arrayFormat: 'repeat' });
};

export const parseQueryString = (query: string) => {
    return qs.parse(query, { ignoreQueryPrefix: true });
};

/**
 * For a given path or url, append query params if they exist. If existing parameters are on the path, it will append them.
 * @param {string} input - the path or url string to append query params to
 * @param {QueryParams | null} params
 * @return {string}
 */
export const appendQueryParams = (input: string, params?: QueryParams | null) => {
    if (!params) {
        return input;
    }

    const [path, paramString] = input.split('?');
    const existingParams = parseQueryString(paramString ?? '');
    const queryString = makeQueryString({ ...existingParams, ...params });
    if (queryString) {
        return `${path}?${queryString}`;
    }
    return input;
};

/**
 * For a given string return only the path portion, excluding any query parameters
 * @param {string} input
 * @return {string}
 */
export const stripQueryString = (input: string) => {
    const [path] = input.split('?');
    return path;
};

/**
 * For a given input path, remove the specified query param(s) from the path. Returns a new path with query params appended
 * @param {string} input - the current path
 * @param {string[]} toRemove varargs for to remove params
 */
export const removeQueryParamFromPath = (input: string, ...toRemove: (QueryParam | string)[]) => {
    if (toRemove.length === 0) {
        return input;
    }

    const [path, queryString] = input.split('?');
    if (!queryString) {
        return path;
    }

    const params = parseQueryString(queryString);

    const updatedParams = { ...params };
    toRemove.forEach((param) => {
        delete updatedParams[param];
    });

    return appendQueryParams(path, updatedParams);
};
