import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type SidebarContextType = {
    collapsed: boolean;
    toggleCollapsed: () => void;
};

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const storedValue = localStorage.getItem('sidebarCollapsed');
            if (storedValue !== null) {
                setCollapsed(storedValue === 'true');
            }
        }
    }, []);

    const toggleCollapsed = () => {
        setCollapsed((prev) => {
            const newCollapsed = !prev;
            if (typeof window !== 'undefined') {
                localStorage.setItem('sidebarCollapsed', newCollapsed.toString());
            }
            return newCollapsed;
        });
    };

    return <SidebarContext.Provider value={{ collapsed, toggleCollapsed }}>{children}</SidebarContext.Provider>;
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};
