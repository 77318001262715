import '@styles/global.scss';
import { AppProps } from 'next/app';
import Head from 'next/head';
import copy from '@copy/Copy';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@/src/theme';
import { useRouter } from 'next/router';
import AnalyticsService, { fireDataLayerEvent, logPageView } from '@services/AnalyticsService';
import React, { useCallback, useContext, useEffect } from 'react';
import { PageLayout } from '@layouts/Layout';
import { SWRConfig } from 'swr';
import useApi from '@hooks/useApi';
import { getConfig, isBrowser } from '@util/config';
import useWatchAuthQueryParams from '@hooks/useWatchAuthQueryParams';
import PlutoDevTools from '@components/devtools/PlutoDevTools';
import Logger from '@util/Logger';
import LocalStorageService, { StorageKey } from '@util/LocalStorageService';
import useAuth from '@hooks/useAuth';
import { AuthContext, AuthContextProvider } from '@contexts/AuthContext';
import { FeatureToggleContextProvider } from '@contexts/FeatureToggleContext';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import 'keen-slider/keen-slider.min.css';
import '@styles/_react-grid.scss';
import { TrialContextProvider } from '@contexts/TrialContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useWatchDevToolsQueryParam from '@hooks/useWatchDevToolsQueryParam';
import { useAppVersionCheck } from '@hooks/useAppVersionCheck';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import * as Sentry from '@sentry/nextjs';
import NProgress from 'nprogress';
import { SidebarProvider } from '@contexts/SidebarContext';

const Config = getConfig();
const logger = Logger.make('_app.tsx');
const authLogger = logger.with('auth_status');
NProgress.configure({ showSpinner: false });

declare global {
    interface Window {
        Cypress: any;
    }
}

if (isBrowser()) {
    window.onerror = function (error) {
        logger.error('unhandled error:', error);
        Sentry.captureException(error);
    };
}

const AppPage = ({ Component, pageProps }: AppProps) => {
    const Config = getConfig();
    const { fetcher } = useApi();
    useWatchAuthQueryParams();
    useWatchDevToolsQueryParam();
    const router = useRouter();
    const path = router.asPath;
    const authContext = useContext(AuthContext);
    const { logout, authReady, user, authLoading } = useAuth();
    const newVersionAvailable = useAppVersionCheck(true);

    const fireAppPageEvent = useCallback(() => {
        fireDataLayerEvent(
            {
                email: user?.email,
                org_name: user?.organization?.name,
            },
            { path, pathname: router.pathname },
        );
    }, [path, router.pathname, user]);

    const handleRouteChange = useCallback(
        (url: string) => {
            window.scroll({ top: 0, left: 0, behavior: 'auto' });
            logPageView(url);
            fireAppPageEvent();
            if (
                !authContext.userHasMultiOrg ||
                url.includes('/login') ||
                url.includes('/signup') ||
                url === '/' ||
                url === `/labspace?organization=${authContext.orgSlug}`
            ) {
                return;
            }
            if (url.includes('/labspace')) return router.replace(`/labspace?organization=${authContext.orgSlug}`);
            if (!url.includes('organization=') || (!!url.includes('organization=') && !router.query.organization)) {
                router.replace({
                    pathname: router.pathname,
                    query: { ...router.query, organization: authContext.orgSlug },
                });
            }
        },
        [fireAppPageEvent],
    );

    const tokenRefreshCheck = () => {
        if (!authContext.user) return; // Ensure only logged-in users trigger this logic

        const status = {
            visibilityState: document?.visibilityState ?? 'hidden',
            online: navigator?.onLine ?? false,
        };

        if (status.visibilityState === 'visible' && status.online) {
            authContext.refreshTokenIfNeeded();
        }
    };

    /**
     * sync user login with other tabs. The method only handles the AUTH_USER_ID storage event.
     * @param {StorageEvent} e
     */
    const handleStorageEvent = (e: StorageEvent) => {
        if (!authReady || e.key !== StorageKey.AUTH_USER_ID) {
            return;
        }

        const storageUserId = e.newValue;
        if (!storageUserId) {
            authLogger.debug('[storageEvent] Log out from other tab');
            logout();
        } else if (storageUserId && !user) {
            authLogger.debug('[storageEvent] Logging in user');
            authContext.refreshAccessToken();
        }
    };

    // Initial app mount/unmount
    useEffect(() => {
        logger.debug(`branchName: ${Config.branchName}`);

        // fire the initial page view
        logPageView(router.asPath);
        fireAppPageEvent();
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement?.removeChild(jssStyles);
        }
    }, []);

    // route change handlers
    useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [handleRouteChange]);

    useEffect(() => {
        const handleStart = () => NProgress.start();
        const handleStop = () => NProgress.done();

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleStop);
        router.events.on('routeChangeError', handleStop);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleStop);
            router.events.off('routeChangeError', handleStop);
        };
    }, [router]);

    /**
     * Refresh auth tokens if needed when the website becomes active/visible/online
     */
    useEffect(() => {
        document?.addEventListener('visibilitychange', tokenRefreshCheck);
        window?.addEventListener('focus', tokenRefreshCheck);
        window?.addEventListener('storage', handleStorageEvent);

        return () => {
            document?.removeEventListener('visibilitychange', tokenRefreshCheck);
            window?.removeEventListener('focus', tokenRefreshCheck);
            window?.removeEventListener('storage', handleStorageEvent);
        };
    }, [authReady]);

    useEffect(() => {
        if (authLoading) return;

        if (user) {
            AnalyticsService.identifyUser(user);
            LocalStorageService.setItem(StorageKey.AUTH_USER_ID, user.uuid ?? null);
            fireAppPageEvent();
        }
    }, [authLoading, user, user?.uuid ?? null]);

    const getLayout = (Component as PageLayout).getLayout ?? ((page) => page);

    const $page = getLayout(<Component {...pageProps} />);

    return (
        <>
            <SWRConfig
                value={{
                    revalidateOnFocus: false,
                    shouldRetryOnError: false,
                    fetcher,
                }}
            >
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <TrialContextProvider>
                            <CssBaseline />
                            <Head>
                                <title>{copy.siteTitleLong}</title>
                                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                            </Head>
                            {$page}
                            {process.env.NODE_ENV === 'development' && <PlutoDevTools />}
                            {newVersionAvailable && (
                                <Snackbar
                                    open
                                    message="A new version of the app is available!"
                                    action={
                                        <Button color="inherit" size="small" onClick={() => window.location.reload()}>
                                            Refresh
                                        </Button>
                                    }
                                />
                            )}
                        </TrialContextProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </SWRConfig>
        </>
    );
};

/** Reenable strict mode after all legacyBehaviors are updated */
const AuthApp = (props: AppProps) => {
    return (
        <React.StrictMode>
            <PlutoErrorBoundary>
                <SidebarProvider>
                    <GoogleOAuthProvider clientId={Config.auth.googleClientId}>
                        <AuthContextProvider>
                            <FeatureToggleContextProvider>
                                <AppPage {...props} />
                            </FeatureToggleContextProvider>
                        </AuthContextProvider>
                    </GoogleOAuthProvider>
                </SidebarProvider>
            </PlutoErrorBoundary>
        </React.StrictMode>
    );
};

export default AuthApp;
