import React, { ReactChild, ReactNode } from 'react';
import cn from 'classnames';
import FieldError, { FormikFieldError } from '@components/forms/FieldError';
import WarningRounded from '@mui/icons-material/WarningRounded';
import { FormHelperText, Tooltip } from '@mui/material';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { isNotBlank } from '@/src/util/StringUtil';
import { isDefined } from '@/src/util/TypeGuards';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

export type TextInputProps = CurrencyInputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
        className?: string;
        componentRight?: ReactNode;
        disabled?: boolean;
        disableFormik?: boolean;
        error?: string | null | boolean;
        errors?: FormikErrors<unknown>;
        hideErrorIcon?: boolean;
        hint?: string | ReactNode;
        hintTop?: boolean;
        iconRight?: ReactNode;
        label?: string | null | ReactNode;
        labelRight?: ReactNode | null;
        name: string;
        noMargin?: boolean;
        py?: number;
        subLabel?: string;
        tooltip?: { title: NonNullable<ReactNode>; href?: string | null; icon?: ReactChild | null } | null;
        touched?: FormikTouched<unknown>;
        type?: string;
        useFormikError?: boolean;
        useNumericFormat?: boolean;
        value?: string | readonly string[] | number | undefined;
    };

/**
 * Note: we use flex-col-reverse so that the HTML elements are rendered with the input field in the DOM first,
 * then any labels after so that the tab index works as expected for the show password field.
 * @param {TextInputProps} props
 * @return {JSX.Element}
 * @constructor
 */
const TextInput: React.FunctionComponent<TextInputProps> = (props: TextInputProps) => {
    const formik = props.disableFormik ? null : useFormikContext();

    const {
        className,
        componentRight,
        disabled = false,
        disableFormik = false,
        errors = formik?.errors,
        hideErrorIcon = false,
        hint,
        hintTop = false,
        iconRight: rightIcon,
        label,
        labelRight,
        name,
        noMargin = false,
        onValueChange,
        py,
        subLabel,
        tooltip,
        touched = formik?.touched,
        type = 'text',
        useFormikError = false,
        useNumericFormat,
        value,
        ...inputProps
    } = props;

    const error = props.error ?? (errors?.[name] && touched?.[name]);

    let iconRight = rightIcon;

    if (error && !hideErrorIcon) {
        iconRight = <WarningRounded className="field-icon right" fontSize="small" />;
    }

    let $tooltip: ReactNode | null = null;
    if (isDefined(tooltip)) {
        const $icon = tooltip.icon ?? <QuestionMarkCircleIcon className="h-4 w-4" />;
        const $child = isNotBlank(tooltip.href) ? (
            <a href={tooltip.href} target="_blank" rel="nofollow noreferrer" className="ml-1">
                {$icon}
            </a>
        ) : (
            $icon
        );
        $tooltip = (
            <Tooltip title={tooltip.title} placement="right" arrow className="ml-1">
                <span>{$child}</span>
            </Tooltip>
        );
    }

    return (
        <div
            className={cn(className, 'form-field', {
                'has-error': !!error,
                disabled,
                'no-margin': noMargin,
            })}
        >
            <div className="flex flex-col-reverse">
                <div className="relative flex items-center">
                    {useNumericFormat ? (
                        <CurrencyInput
                            className={cn('field-input', { 'pr-12': iconRight }, py && { py })}
                            name={name}
                            disabled={disabled}
                            id={name}
                            value={value}
                            onValueChange={onValueChange}
                            {...inputProps}
                        />
                    ) : (
                        <input
                            className={cn('field-input', { 'pr-12': iconRight }, py && { py })}
                            type={type}
                            name={name}
                            id={name}
                            disabled={disabled}
                            value={value}
                            {...inputProps}
                        />
                    )}

                    {iconRight && <span className="absolute right-2 z-10">{iconRight}</span>}
                    {componentRight}
                </div>
                {label && (
                    <label htmlFor={name}>
                        <div
                            className={cn('field-label', {
                                'flex justify-between': !!labelRight,
                                'no-margin': hint && hintTop,
                            })}
                        >
                            <div
                                className={cn({
                                    'flex flex-row items-center': isDefined($tooltip),
                                })}
                            >
                                {label} {subLabel ? <span className="font-normal">{subLabel}</span> : null}
                                {$tooltip}
                            </div>
                            {labelRight}
                        </div>
                        {!disableFormik && hint && hintTop && (
                            <FormHelperText className="inline-block pb-1 !leading-4">{hint}</FormHelperText>
                        )}
                    </label>
                )}
            </div>
            {!disableFormik && hint && !hintTop && (
                <label htmlFor={name}>
                    <FormHelperText className="!leading-4">{hint}</FormHelperText>
                </label>
            )}
            {error && <FieldError cyId={`${name}-error`}>{error}</FieldError>}
            {!disableFormik && useFormikError && !error && name && <FormikFieldError name={name} />}
        </div>
    );
};
export default TextInput;
